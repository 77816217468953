import {ChainId, ERC20Token, WMEER} from '@pancakeswap/sdk'
import {CANDY_MAINNET, USDT_MEER} from './common'

export const qitmeerTokens = {
    wmeer: WMEER[ChainId.QITMEER_EVM],
    meer: new ERC20Token(
        ChainId.QITMEER_EVM,
        '0x470cBFB236860eb5257bBF78715FB5bd77119C2F',
        18,
        'WMEER',
        'Wrapped MEER',
        ''),
    candy: CANDY_MAINNET,
    usdt: USDT_MEER,
    mdao: new ERC20Token(
        ChainId.QITMEER_EVM,
        '0x7c67C897F1feaB083938cBd0ebfadC9cCF48277c',
        18,
        'MDAO',
        'Medina DAO',
        'https://app.medinadao.io/',
    ),
    fy: new ERC20Token(
        ChainId.QITMEER_EVM,
        '0x79B71F321D44F5a6c2208D74197CACf7364DA3d7',
        18,
        'FY',
        'Frog Youth Token',
        ''),
    atm: new ERC20Token(
        ChainId.QITMEER_EVM,
        '0x5BA8FA4E99FF73103804a23c271Ce002dC8300C3',
        18,
        'ATM',
        'ATM',
        ''),
}
