import {useEffect, useMemo} from 'react'
import {useRouter} from 'next/router'
import {Menu as UikitMenu, NextLinkFromReactRouter} from '@pancakeswap/uikit'
import {useTranslation, languageList} from '@pancakeswap/localization'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import useTheme from 'hooks/useTheme'
import {usePhishingBannerManager, useUserSingleHopOnly} from 'state/user/hooks'
import UserMenu from './UserMenu'
import {useMenuItems} from './hooks/useMenuItems'
import GlobalSettings from './GlobalSettings'
import {getActiveMenuItem, getActiveSubMenuItem} from './utils'
import {footerLinks} from './config/footerConfig'
import {SettingsMode} from './GlobalSettings/types'

const Menu = (props) => {
  const {isDark, setTheme} = useTheme()
  // const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const {currentLanguage, setLanguage, t} = useTranslation()
  const {pathname} = useRouter()
  const [showPhishingWarningBanner] = usePhishingBannerManager()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({menuConfig: menuItems, pathname})
  const activeSubMenuItem = getActiveSubMenuItem({menuItem: activeMenuItem, pathname})

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  useEffect(() => {
    setSingleHopOnly(true)
    setTheme('dark')
  })
  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false}/>
        }}
        rightSide={
          <>
            {/* <GlobalSettings mode={SettingsMode.GLOBAL}/> */}
            {/* <NetworkSwitcher /> */}
            <UserMenu/>
          </>
        }
        banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner/>}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        // cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        // buyCakeLabel={t('Buy CAKE')}
        {...props}
      />
    </>
  )
}

export default Menu
