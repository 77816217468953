import React, {useContext} from "react";
import styled, {keyframes} from "styled-components";
import Flex from "../../../components/Box/Flex";
import {MenuContext} from "../context";
import {CandyLogoIcon, CandyLogoWithTextIcon} from "../../../components/Svg";

interface Props {
  href: string;
}

const blink = keyframes`
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.1);
  }
`;

const StyledLink = styled("a")`
  display: flex;
  align-items: center;

  .mobile-icon {
    width: 32px;

    ${({theme}) => theme.mediaQueries.lg} {
      display: none;
    }
  }

  .desktop-icon {
    width: 160px;
    display: none;

    ${({theme}) => theme.mediaQueries.lg} {
      display: block;
    }
  }

  .eye {
    animation-delay: 20ms;
  }

  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`;
const Logo: React.FC<React.PropsWithChildren<Props>> = ({href}) => {
  const {linkComponent} = useContext(MenuContext);
  const isAbsoluteUrl = href.startsWith("http");
  const innerLogo = (
    <>
      <CandyLogoIcon className="mobile-icon"/>
      <CandyLogoWithTextIcon className="desktop-icon"/>
    </>
  );

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="Candy home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink href={href} as={linkComponent} aria-label="Candy home page">
          {innerLogo}
        </StyledLink>
      )}
    </Flex>
  );
};

export default React.memo(Logo);
