import React from "react";
import Svg from "../Svg";
import {SvgProps} from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 580 82" {...props}>
      <g>
        <path d="M40.8,2.91C61.86,2.91,79,20.05,79,41.12S61.86,79.34,40.8,79.34S2.59,62.2,2.59,41.12
					S19.73,2.91,40.8,2.91 M40.8,0.91c-22.2,0-40.2,18.01-40.2,40.22s18,40.22,40.2,40.22S81,63.34,81,41.12S63,0.91,40.8,0.91
					L40.8,0.91z" fill="#6ABD79"/>
      </g>
      <g>
        <ellipse cx="40.89" cy="41.34" rx="36.89" ry="36.91" fill="#6ABD79"/>
      </g>

      <g>
        <path d="M70.86,35.09c-4.18-18.72-23.98-18.45-23.98-18.45c-19.32,2.52-21.22,18.9-21.22,18.9
					C23.8,49.91,33.5,54.45,33.5,54.45c11.51,6.31,19.37-4.15,19.37-4.15c7.68-10.99-0.46-17.98-0.46-17.98
					C43.99,27.07,39.5,33.7,39.5,33.7c4.69-0.51,6.92,2.77,6.92,2.77c5.46,10.34-5.08,11.99-5.08,11.99
					c-10.4-1.27-7.38-12.91-7.38-12.91c3.65-12.17,15.68-10.6,15.68-10.6c15.7,2.17,13.83,18.45,13.83,18.45
					c-3.21,15.94-15.22,18.9-15.22,18.9c-13.83,4.57-23.06-4.62-23.06-4.62s-5.57-5-6.91-13.38c-1.23-7.64,1.85-15.22,1.85-15.22
					c9.71-20.24,32.75-15.67,32.75-15.67c-6.1-5.37-19.37-2.31-19.37-2.31C15.59,16.71,11.84,30,11.84,30
					c-6.53,25.04,9.68,35.05,9.68,35.05c21.54,16.66,39.66-0.91,39.66-0.91C74.94,51.15,70.86,35.09,70.86,35.09z"
              fill="#FFFFFF"/>
        <path d="M138.56,17.17l1.39,12.39c-1.36-0.85-2.83-1.48-4.4-1.9c-1.57-0.42-3.27-0.63-5.09-0.64
					c-3.64,0-6.52,1.27-8.63,3.8c-2.11,2.54-3.17,5.99-3.17,10.37c0,4.33,0.92,7.61,2.76,9.82s4.56,3.32,8.16,3.32
					c1.71,0,3.44-0.29,5.18-0.86c1.74-0.57,3.66-1.49,5.74-2.74l-1.01,11.12c-1.76,1.08-3.7,1.89-5.84,2.42
					c-2.14,0.53-4.49,0.79-7.07,0.79c-6.57,0-11.72-2-15.47-6c-3.75-4-5.62-9.46-5.62-16.37c0-8.34,2.24-14.82,6.72-19.44
					c4.48-4.62,10.76-6.92,18.84-6.92c1.21,0,2.44,0.07,3.67,0.21C135.96,16.68,137.24,16.89,138.56,17.17z"
              fill="#FFFFFF"/>
        <path d="M161.04,17.24c1.36,0.06,2.76,0.11,4.18,0.15c1.42,0.03,2.83,0.05,4.24,0.05c1.45,0,2.9-0.02,4.36-0.05
					c1.45-0.03,2.88-0.08,4.29-0.15c1.58,6.46,3.67,13.6,6.27,21.42c2.6,7.82,5.71,16.31,9.32,25.46l-15.25,0
					c-0.41-2.43-0.82-4.57-1.22-6.44c-0.4-1.86-0.8-3.45-1.19-4.75l-14.11,0c-0.37,1.32-0.75,2.92-1.15,4.78
					c-0.4,1.86-0.82,4-1.25,6.4l-13.85,0c4.21-10.68,7.59-19.94,10.15-27.76C158.39,28.53,160.13,22.16,161.04,17.24z M169.19,26.64
					c-0.95,2.79-1.84,5.56-2.67,8.29c-0.82,2.73-1.67,5.67-2.54,8.81l9.92,0c-0.43-2.08-1.05-4.58-1.85-7.49
					C171.25,33.33,170.3,30.13,169.19,26.64z" fill="#FFFFFF"/>
        <path d="M201.65,17.25l13.43,0c4.2,7.63,7.58,13.59,10.12,17.88c2.54,4.29,5.03,8.26,7.46,11.9
					c0-0.58,0.01-1.44,0.03-2.57c0.02-1.13,0.03-1.96,0.03-2.5c0-4.03-0.1-8.09-0.29-12.17c-0.19-4.08-0.47-8.26-0.84-12.53l13.04,0
					c-0.39,4.31-0.69,8.58-0.9,12.79c-0.21,4.22-0.31,8.36-0.31,12.43c0,3.94,0.06,7.7,0.19,11.26s0.31,7.03,0.55,10.39l-13.33,0
					c-2.17-4.33-4.7-8.83-7.6-13.48c-2.9-4.65-6.26-9.61-10.07-14.87l0,4.52c0,4.72,0.09,9.05,0.27,12.99
					c0.18,3.93,0.46,7.55,0.83,10.84l-13.23,0c0.43-4.14,0.77-8.19,0.99-12.14c0.23-3.95,0.34-7.83,0.34-11.62
					c0-3.92-0.06-7.82-0.19-11.69C202.04,24.81,201.87,21,201.65,17.25z" fill="#FFFFFF"/>
        <path d="M256.28,17.27l9.46,0c5.68,0,9.89,0.17,12.63,0.49c2.74,0.33,5.18,0.86,7.33,1.6
					c4.25,1.45,7.54,3.95,9.88,7.5c2.34,3.54,3.51,7.79,3.51,12.73c0,4.2-0.78,7.96-2.33,11.28c-1.55,3.32-3.82,6.08-6.81,8.29
					c-2.43,1.82-5.38,3.11-8.84,3.87c-3.47,0.76-8.89,1.14-16.25,1.13l-8.58,0c0.41-4.23,0.72-8.29,0.91-12.19
					c0.2-3.9,0.29-7.71,0.3-11.44c0-3.53-0.1-7.18-0.29-10.95C256.99,25.78,256.69,21.69,256.28,17.27z M270.38,54.95h1.14
					c4.36,0,7.74-1.26,10.16-3.79c2.42-2.52,3.63-6.05,3.63-10.58c0-4.62-1.02-8.1-3.07-10.45c-2.05-2.35-5.09-3.53-9.12-3.53
					c-0.37,0-1.04,0.03-2.02,0.1c-0.26,0.02-0.46,0.03-0.58,0.03c-0.09,2.25-0.15,4.64-0.2,7.15c-0.04,2.51-0.07,5.26-0.07,8.23
					c0,3.25,0.01,5.79,0.03,7.61C270.3,51.53,270.33,53.28,270.38,54.95z" fill="#FFFFFF"/>
        <path d="M319.54,44.52l-0.03-0.07c-6.52-12.22-11.77-21.28-15.76-27.18l15.18,0c0.93,2.49,2.03,5.15,3.28,7.96
					c1.26,2.82,2.78,6.05,4.58,9.69c1.63-2.97,3.13-5.92,4.52-8.86c1.39-2.94,2.66-5.87,3.81-8.79l13.26,0
					c-2.73,4.01-5.43,8.21-8.1,12.59c-2.67,4.39-5.39,9.16-8.16,14.32c0.06,3.86,0.19,7.45,0.39,10.78
					c0.19,3.33,0.46,6.39,0.81,9.18l-15.12,0c0.43-3.34,0.77-6.53,0.99-9.59s0.34-6,0.34-8.84V44.52z"
              fill="#FFFFFF"/>
        <path d="M407.54,17.43l0.94,10.27c-1.6-0.56-3.14-0.99-4.62-1.27c-1.47-0.28-2.94-0.42-4.39-0.42
					c-2.21,0-3.95,0.37-5.22,1.1c-1.27,0.74-1.9,1.72-1.9,2.96c0,1.69,2.31,3.85,6.92,6.47l0.65,0.39c4.4,2.52,7.25,4.66,8.57,6.42
					c1.31,1.77,1.96,3.92,1.96,6.45c0,4.7-1.85,8.44-5.54,11.21c-3.7,2.77-8.71,4.16-15.04,4.16c-1.54,0-3.12-0.11-4.75-0.33
					c-1.63-0.22-3.28-0.53-4.97-0.94l-1.66-10.53c2.12,0.63,4.1,1.1,5.92,1.42c1.82,0.32,3.49,0.47,5.01,0.47
					c2.51,0,4.44-0.35,5.77-1.06c1.33-0.7,2-1.71,2-3.01c0-1.54-1.87-3.37-5.62-5.5c-1.04-0.58-1.84-1.04-2.41-1.37
					c-4.09-2.36-6.79-4.46-8.08-6.29c-1.29-1.83-1.93-4.11-1.93-6.84c0-4.49,1.72-8.09,5.15-10.82c3.44-2.73,8.03-4.09,13.77-4.09
					c2.34,0,5.31,0.35,8.91,1.04C407.22,17.38,407.41,17.41,407.54,17.43z" fill="#FFFFFF"/>
        <path d="M416.1,17.31l14.79,0c0.56,5.74,2.58,15.41,6.04,29l0.75,2.89c2.49-7.48,4.56-13.79,6.2-18.93
					s2.94-9.46,3.92-12.95l8,0c1.06,4.12,2.34,8.8,3.85,14.04c1.5,5.25,3.26,11.15,5.28,17.72c1.58-4.94,2.96-10.06,4.12-15.36
					c1.16-5.3,2.1-10.76,2.82-16.4l13.49,0c-3.1,8.39-5.86,16.49-8.26,24.31c-2.41,7.82-4.45,15.34-6.12,22.56l-12.51,0
					c-0.67-2.95-1.63-6.44-2.87-10.47c-1.25-4.03-2.84-8.86-4.79-14.5c-1.67,5.03-3.11,9.6-4.33,13.72
					c-1.21,4.12-2.23,7.87-3.06,11.25l-12.84,0c-1.65-7.02-3.67-14.46-6.09-22.3C422.05,34.04,419.26,25.85,416.1,17.31z"
              fill="#FFFFFF"/>
        <path d="M503.36,17.33c1.37,0.07,2.76,0.11,4.18,0.15c1.42,0.03,2.83,0.05,4.24,0.05c1.45,0,2.9-0.02,4.36-0.05
					s2.88-0.08,4.29-0.15c1.58,6.46,3.67,13.6,6.27,21.42c2.6,7.82,5.71,16.31,9.32,25.46l-15.25,0c-0.41-2.43-0.82-4.57-1.22-6.44
					c-0.4-1.86-0.79-3.45-1.19-4.75l-14.11,0c-0.37,1.32-0.75,2.92-1.16,4.78c-0.4,1.86-0.82,4-1.25,6.4l-13.85,0
					c4.21-10.68,7.59-19.94,10.15-27.76C500.71,28.62,502.45,22.25,503.36,17.33z M511.52,26.72c-0.96,2.8-1.84,5.56-2.67,8.29
					c-0.83,2.73-1.67,5.67-2.54,8.81l9.91,0c-0.43-2.08-1.05-4.58-1.85-7.49C513.58,33.42,512.62,30.21,511.52,26.72z"
              fill="#FFFFFF"/>
        <path d="M543.81,17.34l11.93,0c3.94,0,6.96,0.1,9.05,0.29c2.09,0.2,3.88,0.52,5.38,0.98
					c2.97,0.91,5.3,2.55,6.99,4.93c1.69,2.37,2.53,5.2,2.53,8.47c0,5.44-1.91,9.64-5.73,12.6c-3.81,2.96-9.24,4.43-16.29,4.43
					c0.02,3.01,0.11,5.76,0.26,8.24c0.15,2.48,0.37,4.8,0.65,6.94l-14.79,0c0.41-4.23,0.72-8.29,0.91-12.19
					c0.2-3.9,0.29-7.71,0.29-11.44c0-3.53-0.09-7.18-0.29-10.95C544.53,25.85,544.22,21.76,543.81,17.34z M557.59,40.03
					c0.19,0.04,0.39,0.07,0.6,0.08c0.21,0.01,0.51,0.02,0.93,0.02c2.51,0,4.44-0.62,5.79-1.87c1.34-1.25,2.02-3.03,2.02-5.35
					c0-2.12-0.61-3.73-1.83-4.81c-1.22-1.08-3.03-1.63-5.41-1.63c-0.39,0-0.73,0.01-1.02,0.02c-0.29,0.01-0.57,0.03-0.83,0.05
					c-0.09,1.6-0.15,3.24-0.18,4.91c-0.03,1.67-0.05,3.36-0.05,5.07L557.59,40.03z" fill="#FFFFFF"/>
      </g>
    </Svg>
  );
};

export default Logo;
