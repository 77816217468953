import React from "react";
import Svg from "../Svg";
import {SvgProps} from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <g>
        <path fill="#6ABD79" d="M50.05,3C76.22,3,97.5,24.3,97.5,50.47S76.22,97.94,50.05,97.94S2.6,76.65,2.6,50.47S23.89,3,50.05,3
			 M50.05,1C22.74,1,0.6,23.15,0.6,50.47c0,27.32,22.14,49.47,49.45,49.47S99.5,77.79,99.5,50.47C99.5,23.15,77.36,1,50.05,1
			L50.05,1z"/>
      </g>
      <g>
        <ellipse fill="#6ABD79" cx="50.17" cy="50.74" rx="45.38" ry="45.4"/>
      </g>
      <g>
        <path fill="#FFFFFF" d="M87.03,43.05c-5.14-23.02-29.49-22.7-29.49-22.7c-23.76,3.1-26.1,23.25-26.1,23.25
			c-2.3,17.68,9.64,23.26,9.64,23.26c14.15,7.76,23.83-5.1,23.83-5.1c9.45-13.52-0.56-22.12-0.56-22.12
			c-10.37-6.45-15.88,1.7-15.88,1.7c5.77-0.62,8.51,3.41,8.51,3.41c6.72,12.72-6.24,14.75-6.24,14.75
			c-12.79-1.56-9.07-15.88-9.07-15.88c4.48-14.97,19.29-13.04,19.29-13.04c19.31,2.67,17.01,22.69,17.01,22.69
			c-3.95,19.61-18.72,23.25-18.72,23.25c-17.02,5.62-28.36-5.68-28.36-5.68s-6.85-6.15-8.5-16.45c-1.51-9.4,2.27-18.72,2.27-18.72
			c11.95-24.9,40.28-19.28,40.28-19.28c-7.5-6.6-23.82-2.84-23.82-2.84c-22.05,6.9-26.67,23.25-26.67,23.25
			c-8.04,30.8,11.9,43.11,11.9,43.11c26.49,20.49,48.78-1.12,48.78-1.12C92.05,62.8,87.03,43.05,87.03,43.05z"/>
      </g>
    </Svg>
  );
};

export default Icon;
