import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import { Box, Flex } from "../Box";
import {
  StyledFooter,
  StyledIconMobileContainer,





} from "./styles";

import { CandyLogoWithTextIcon } from "../Svg";
import { FooterProps } from "./types";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  ...props
}) => {
  const isMounted = useIsMounted();
  return (
    <StyledFooter
      data-theme="dark"
      p={["30px 16px 6px", null, "46px 40px 32px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
    >
      <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
        <StyledIconMobileContainer display={["block", null, "none"]}>
          <CandyLogoWithTextIcon width="140px" />
        </StyledIconMobileContainer>
        <Flex
          // order={[2, null, 1]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
          alignItems="flex-start"
          mb={["42px", null, "36px"]}
        >
          {/* {items?.map((item) => ( */}
          {/*   <StyledList key={item.label}> */}
          {/*     <StyledListItem>{item.label}</StyledListItem> */}
          {/*     {item.items?.map(({ label, href, isHighlighted = false }) => ( */}
          {/*       <StyledListItem key={label}> */}
          {/*         {href ? ( */}
          {/*           <Link */}
          {/*             data-theme="dark" */}
          {/*             href={href} */}
          {/*             target="_blank" */}
          {/*             rel="noreferrer noopener" */}
          {/*             color={isHighlighted ? vars.colors.warning : "text"} */}
          {/*             bold={false} */}
          {/*           > */}
          {/*             {label} */}
          {/*           </Link> */}
          {/*         ) : ( */}
          {/*           <StyledText>{label}</StyledText> */}
          {/*         )} */}
          {/*       </StyledListItem> */}
          {/*     ))} */}
          {/*   </StyledList> */}
          {/* ))} */}
          <Box display={["none", null, "block"]}>
            <CandyLogoWithTextIcon width="160px" />
          </Box>
        </Flex>
        {/* <StyledSocialLinks order={[2]} pb={["42px", null, "32px"]} mb={["0", null, "32px"]} /> */}
        {/* <StyledToolsContainer */}
        {/*   data-theme="dark" */}
        {/*   order={[1, null, 3]} */}
        {/*   flexDirection={["column", null, "row"]} */}
        {/*   justifyContent="space-between" */}
        {/* > */}
        {/*   <Flex order={[2, null, 1]} alignItems="center"> */}
        {/*     <SkeletonV2 variant="round" width="56px" height="32px" isDataReady={isMounted}> */}
        {/*       <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} /> */}
        {/*     </SkeletonV2> */}
        {/*     <LangSelector */}
        {/*       currentLang={currentLang} */}
        {/*       langs={langs} */}
        {/*       setLang={setLang} */}
        {/*       color="textSubtle" */}
        {/*       dropdownPosition="top-right" */}
        {/*     /> */}
        {/*   </Flex> */}
        {/*   <Flex order={[1, null, 2]} mb={["24px", null, "0"]} justifyContent="space-between" alignItems="center"> */}
        {/*     <Box mr="20px"> */}
        {/*       <CakePrice cakePriceUsd={cakePriceUsd} color="textSubtle" /> */}
        {/*     </Box> */}
        {/*     <Button */}
        {/*       data-theme={isDark ? "dark" : "light"} */}
        {/*       as="a" */}
        {/*       href="https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82&chainId=56" */}
        {/*       target="_blank" */}
        {/*       scale="sm" */}
        {/*       endIcon={<ArrowForwardIcon color="backgroundAlt" />} */}
        {/*     > */}
        {/*       {buyCakeLabel} */}
        {/*     </Button> */}
        {/*   </Flex> */}
        {/* </StyledToolsContainer> */}
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
